<template>
  <div class="getCoinBox">
    <NavBar title="提取">
      <template #right>
        <TextBox color="#333333" :font-size="26" @click.native="goRecord">记录</TextBox>
      </template>
    </NavBar>
    <div class="fixedTips" v-if="fixedTips">每周三 9:00-17:00开放提取</div>
    <div class="contentBox">
      <Space :height="30"></Space>
      <van-field
        type="text"
        :value="coin && coin.name"
        readonly
        class="bg radius10 targetCoin"
        right-icon="arrow-down"
        @click="onTargetCoinPicker"
      />
      <van-popup v-model="showTargetCoinPicker" round position="bottom">
        <van-picker
          show-toolbar
          :columns="coinList.map(item => item.name)"
          @cancel="showTargetCoinPicker = false"
          @confirm="onConfirm"
        />
      </van-popup>
      <Space :height="50"></Space>
      <div class="pd24">
        <TextBox color="textColor1">链类型</TextBox>
        <Space :height="27"></Space>
        <div class="linkBox">
          <div
            :class="{ active: chainActive === index }"
            v-for="(item, index) of chainList"
            :key="index"
            @click="chainActive = index"
          >
            {{ item }}
          </div>
        </div>
      </div>
      <Space :height="40"></Space>
      <TextBox color="textColor1" class="pd24">提取地址</TextBox>
      <Space :height="5"></Space>
      <van-field
        type="text"
        placeholder="添加并选择地址"
        :right-icon="require('@/assets/images/personal/myAssets/c1.png')"
        @click-right-icon="goCoinSite"
        readonly
        v-model="site"
      />
      <van-divider />
      <Space :height="30"></Space>
      <TextBox color="textColor1" class="pd24">数量</TextBox>
      <Space :height="5"></Space>
      <van-field
        type="number"
        :placeholder="`最小提取数量${coin.withdrawMin || 0}`"
        v-model="number"
        :formatter="res => $toFixed(res, coin.xiaoShuDian)"
      >
        <template #button>
          <TextBox color="intro1">{{ coin.name }}</TextBox>
          <div class="numberDividerColumn"></div>
          <van-button type="info" plain @click="setAll">全部</van-button>
        </template>
      </van-field>
      <van-divider />
      <Space :height="30"></Space>
      <TextBox color="intro1" class="pd24">
        可用：{{ $toFixed(userAssetsInfo.available, userAssetsInfo.xiaoShuDian, true) }} {{ coin.name }}
      </TextBox>
      <Space :height="30"></Space>
      <template v-if="coinList.length">
        <TextBox color="textColor1" class="pd24">手续费</TextBox>
        <Space :height="5"></Space>
        <van-field type="text" readonly :value="coin.fee">
          <template #button>
            <TextBox color="intro1">{{ coin.name }}</TextBox>
          </template>
        </van-field>
        <van-divider />
        <Space :height="30"></Space>
      </template>
      <TextBox color="textColor1" class="pd24">验证码</TextBox>
      <Space :height="5"></Space>
      <van-field type="number" placeholder="请输入短信验证码" v-model="code">
        <template #button>
          <SendCode url="/v1/assets/withdrawCode" no-phone v-if="coinList.length"></SendCode>
        </template>
      </van-field>
      <van-divider />
      <Space :height="57"></Space>
      <TextBox color="intro1" :font-size="24">注意事项：</TextBox>
      <Space :height="30"></Space>
      <TextBox color="intro1" :font-size="24" style="line-height: 1.8;">
        为保障资金安全，当您账户安全策略变更、密码修改、我们会对提取进行人工审核，请耐心等待工作人员电话或邮件联系。请务必确认电脑及浏览器安全，防止信息被篡改或泄露。<br />
      </TextBox>
      <Space :height="96"></Space>
      <div class="dfjcsbaic">
        <TextBox color="textColor1">到账数量</TextBox>
        <TextBox color="textColor1">
          {{ $toFixed(overNumber, userAssetsInfo.xiaoShuDian, true) }} {{ coin.name }}
        </TextBox>
      </div>
      <Space :height="28"></Space>
      <van-button
        type="primary"
        :disabled="!coinList.length"
        block
        @click="submit"
        :loading="$store.state.submitLoading"
        >提取</van-button
      >
      <Space :height="56"></Space>
    </div>
  </div>
</template>
<script>
export default {
  name: '',
  // mixins: [],
  // components: {},
  // props: {},
  data() {
    return {
      fixedTips: false,
      showTargetCoinPicker: false,
      coinActive: 0,
      coinList: [],
      chainActive: 0,
      chainList: [],
      site: '',
      number: '',
      code: '',
      userAssetsInfo: '',
      siteList: [],
    };
  },
  created() {
    this.getCoinList();
    this.site = this.$store.state.myAssets.defaultSite;
  },
  // mounted () {},
  methods: {
    async getList() {
      let res = await this.$http('get', '/v1/addr/addrRecord', {
        coinId: this.coin.id,
        page: 1,
        size: 10000,
      });
      this.siteList = res.data.list;
    },
    getCoinList() {
      this.$http('get', '/v1/assets/coinInfo').then(res => {
        let list = res.data.filter(item => {
          return item.isWithdraw === 1;
        });
        this.coinList = list;
        let urlCoinId = this.$route.query.coinId;
        if (urlCoinId) {
          list.forEach((item, index) => {
            if (item.id === Number(urlCoinId)) {
              this.coinActive = index;
            }
          });
        }
        let getCoinObj = this.$store.state.myAssets.getCoinObj;
        if (getCoinObj) {
          list.forEach((item, index) => {
            if (item.id === getCoinObj.id) {
              this.coinActive = index;
            }
          });
        }
      });
    },
    onTargetCoinPicker() {
      if (!this.coinList.length) {
        return;
      }
      this.showTargetCoinPicker = true;
    },
    getUserAssetsInfo() {
      this.$http('post', '/v1/user/userAssetsInfo', {
        coinId: this.coin.id,
        walletAccountId: this.$store.state.personal.userActive ? 3 : 1,
      })
        .then(res => {
          this.userAssetsInfo = res.data;
        })
        .catch(() => {});
    },
    updateChainList() {
      if (this.coin.name.toUpperCase() === 'FIL') {
        this.chainList = ['FIL'];
        this.chainActive = 0;
      } else {
        // this.chainList = ['ERC20', 'TRC20'];
        this.chainList = ['ERC20'];
        this.chainActive = 0;
      }
    },
    goCoinSite() {
      if (!this.coinList.length) {
        return;
      }
      this.$store.commit('myAssets/save', {
        getCoinObj: { ...this.coin },
      });
      this.$router.push(`/personal/myAssets/getCoinSite?id=${this.coin.id}`);
    },
    goRecord() {
      this.$router.push(`/personal/myAssets/addGetRecord?active=1&coinId=${this.coin.id}`);
    },
    onConfirm(value, index) {
      this.coinActive = index;
      this.showTargetCoinPicker = false;
      this.reset();
    },
    setAll() {
      this.number = this.userAssetsInfo.available;
    },
    submit: _.debounce(function() {
      if (!this.site) {
        this.$toast('提取地址不能为空');
        return;
      }
      if (!this.number) {
        this.$toast('提取数量不能为空');
        return;
      }
      if (this.number > this.userAssetsInfo.available || this.userAssetsInfo.available <= 0) {
        this.$toast('币种可用余额不足');
        return;
      }
      if (this.number < this.coin.withdrawMin) {
        this.$toast('提取数量必须大于最小提取数量');
        return;
      }
      if (!this.code) {
        this.$toast('请输入短信验证码');
        return;
      }
      this.$store.commit('setSubmitLoading', true);
      this.$http('post', '/v1/assets/withdraw', {
        amount: this.number,
        chainType: this.chainList[this.chainActive],
        code: this.code,
        coinId: this.coin.id,
        fee: this.coin.fee,
        addrId: this.siteList.find(res => this.site === res.address).id,
        walletAccountId: this.$store.state.personal.userActive ? 3 : 1,
      })
        .then(res => {
          let that = this;
          this.$toast('提交成功');
          that.$router.back();
        })
        .all(() => {
          this.$store.commit('setSubmitLoading', false);
        });
    }),
    reset() {
      this.site = '';
      this.number = '';
      this.code = '';
    },
    watchCoin() {
      this.updateChainList();
      this.getUserAssetsInfo();
      if (!this.site) {
        if (this.coinList.length) {
          this.site = this.coinList[this.coinActive].addr;
        }
      }
    },
  },
  watch: {
    coinActive(res) {
      this.watchCoin();
    },
    coinList(res) {
      if (!this.coinList.length) {
        this.fixedTips = true;
        return;
      } else {
        this.fixedTips = false;
      }
      this.watchCoin();
    },
    chainActive(res) {
      this.getUserAssetsInfo();
    },
    coin(res) {
      this.getList();
    },
  },
  computed: {
    overNumber() {
      if (!this.number) return 0;
      let res = this.number - this.coin.fee;
      return res < 0 ? 0 : res;
    },
    coin() {
      return (
        this.coinList[this.coinActive] || {
          name: '',
        }
      );
    },
  },
  // filters: {},
  // beforeDestroy () {},
};
</script>
<style scoped lang="less">
.fixedTips {
  position: fixed;
  top: 92px;
  left: 0;
  width: 100%;
  height: 70px;
  padding: 8px 16px;
  .dfjccaic;
  background-color: #ff976a;
  color: white;
  z-index: 1;
}
.getCoinBox {
  .targetCoin {
    font-weight: bold;
    font-size: 36px;
  }
  .linkBox {
    display: flex;
    > div {
      padding: 11px 24px;
      background: rgba(168, 168, 181, 0.12);
      border-radius: 10px;
      font-size: 28px;
      font-weight: 400;
      color: #353470;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 30px;
      line-height: 40px;
    }
    .active {
      background: #525298;
      color: @white;
    }
  }
  .numberDividerColumn {
    margin: 0 32px;
    width: 1px;
    height: 40px;
    border-right: 1px solid #f0f0f0;
  }
}
</style>
